import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

const Nav = styled.div`
    width: 90%;
    max-width: calc(var(--width)*0.9);   
    ul{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style: none;
        padding: 0;
        margin: 0;
    }
    @media screen and (max-width: 780px){
        ul{
            text-align: center;
            display: inline-block;
        }
    }
`

const Wrapper = styled.div`
    width: 100vw;
    display: flex;
    position: relative;
    width: 100%;
    max-width: var(--width);
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    article{
        width: 100%;
        padding: 12px;
        section{
            h2 {
                width: 100%;
                position: relative;
                font-size: 1.2rem;
                margin-top: 2.5rem;
                margin-bottom: 1rem;
                padding-bottom: 10px;
                font-weight: 600;
                width: 100%;
                vertical-align: center;               
                &:before {
                    position: absolute;
                    content: "";
                    background: rgb(126,128,130);
                    background: -moz-linear-gradient(left,  rgba(126,128,130,1) 10%, rgba(224,224,224,1) 10%);
                    background: -webkit-linear-gradient(left,  rgba(126,128,130,1) 10%,rgba(224,224,224,1) 10%);
                    background: linear-gradient(to right,  rgba(126,128,130,1) 10%,rgba(224,224,224,1) 10%);
                    width: 100%;
                    height: 3px;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    margin: auto;
                    opacity: 0.7;
                }
            }
            h3 {
                font-size: 1.1rem;
                font-weight: 600;
                margin-top: 1rem;
                margin-bottom: 0.7rem;
                display: flex;
                /* padding: 7px 0 7px 15px; */
                position: relative;
                /* &:before {
                    content: "";
                    width: 3px;
                    height: 100%;
                    background: rgb(126,128,130);
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    border-radius: 5px;
                    opacity: 0.8;
                } */
            }
            p {
                margin-top: 0.5rem;
                margin-bottom: 0.5rem;
            }
            ul {
                margin-top: 0;
                margin-bottom: 0;
                margin-left: 2.5rem;
            }
            li {
                margin-top: 0;
                margin-bottom: 0;
            }
            ul li p {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
    }
`

const BlogPostTemplate = ({ data, pageContext, location }) => {
    const post = data.markdownRemark
    const siteTitle = data.site.siteMetadata.title
    const { previous, next } = pageContext

    return (
        <Layout location={location} title={siteTitle}>
            <SEO
                title={post.frontmatter.title}
                description={post.frontmatter.description || post.excerpt}
            />
            <Wrapper>
                <article>
                    <header>
                        <h2
                            style={{
                                marginTop: 10,
                                marginBottom: 5,
                            }}
                        >
                            {post.frontmatter.title}
                        </h2>
                        <div
                            style={{
                                display: `block`,
                                marginBottom: rhythm(1),
                                color: `#adadad`
                            }}
                        >
                            <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: `6px` }} /> {post.frontmatter.date}
                        </div>
                    </header>
                    <section dangerouslySetInnerHTML={{ __html: post.html }} />
                    <hr
                        style={{
                            marginTop: `3rem`,
                            marginBottom: `1rem`
                        }}
                    />
                </article>
            </Wrapper>
            <Nav>
                <ul>
                    <li>
                        {previous && (
                            <Link to={previous.fields.slug} rel="prev">
                                ← {previous.frontmatter.title}
                            </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                            <Link to={next.fields.slug} rel="next">
                                {next.frontmatter.title} →
                            </Link>
                        )}
                    </li>
                </ul>
            </Nav>
        </Layout>
    )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "YYYY-MM-DD")
        updated(formatString: "YYYY-MM-DD")
        description
      }
    }
  }
`
